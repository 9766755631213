body {
  margin: 0;
  padding: 0;
}

h3 {
  margin: 0.3rem;
  padding: 0;
}

.mainLink {
  color: white;
  text-decoration: none;
}

.activeMainLink {
  font-weight: bold;
}

li {
  list-style-type: none;
}

.NavLink {
  text-decoration: none;
  color: white;
}

.DashBoard {
  margin-top: 1rem;
}

.SignIn {
  padding-left: 1.5rem;
  color: gray;
}

.red-text {
  color: red;
  margin-left: 3rem;
}

.CreateLink {
  padding-left: 1.5rem;
  color: gray;
}

.AssetHeader {
  display: flex;
  justify-content: center;
}

.Toolbar {
  margin-top: 1rem;
  padding-left: 1.5rem;
}
